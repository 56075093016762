import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7d2ab868")
const _hoisted_1 = { class: "p-grid p-formgrid" }
const _hoisted_2 = { class: "p-field" }
const _hoisted_3 = {
  key: 0,
  for: "courseSelector"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "p-col-6 p-mt-4" }
const _hoisted_6 = { style: {"display":"flex"} }
const _hoisted_7 = {
  class: "p-col-3",
  style: {"float":"right","margin":"2pt","width":"100%"}
}
const _hoisted_8 = {
  class: "p-col-3",
  style: {"float":"right","margin":"2pt","width":"25%"}
}
const _hoisted_9 = { style: {"width":"200%"} }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_ChTag = _resolveComponent("ChTag")!
  const _component_RolSelector = _resolveComponent("RolSelector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.label ? 'p-col-6' : 'p-col-6 p-mt-4')
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_MultiSelect, {
          panelClass: "overlay-gray",
          modelValue: _ctx.modelValue,
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.value))),
          options: _ctx.coursePlans,
          filter: true,
          showToggleAll: false,
          optionLabel: "information.name",
          dataKey: "id",
          optionValue: "id",
          placeholder: _ctx.placeholder,
          display: "chip"
        }, null, 8, ["modelValue", "options", "placeholder"]),
        (_ctx.helperText)
          ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.helperText), 1))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (course) => {
        return (_openBlock(), _createElementBlock("div", { key: course }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ChTag, {
                showRemoveButton: true,
                onOnRemove: ($event: any) => (
                _ctx.$emit(
                  'update:modelValue',
                  _ctx.toggleElementsFromArray(course, _ctx.modelValue)
                )
              ),
                class: "p-mb-2 w100"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.coursePlans.find((c) => c.id === course)?.information.name), 1)
                ]),
                _: 2
              }, 1032, ["onOnRemove"])
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_8, [
              _createVNode(_component_RolSelector, {
                onChangeRolSelector: ($event: any) => {_ctx.changeRolSelector($event, course);
              _ctx.$emit(
                'changeRolCanBeTeacherOf',
                {
                  course: course,
                  rol: $event
                }
              );},
                selectedRol: _ctx.selRol,
                selectedCourse: _ctx.selCourse,
                course: course,
                uniqueId: 'id_' + course
              }, null, 8, ["onChangeRolSelector", "selectedRol", "selectedCourse", "course", "uniqueId"])
            ], 512), [
              [_vShow, _ctx.creationMod]
            ])
          ]),
          _withDirectives(_createElementVNode("hr", _hoisted_9, null, 512), [
            [_vShow, _ctx.modelValue.indexOf(course) !== _ctx.modelValue.length - 1]
          ])
        ]))
      }), 128))
    ])
  ]))
}