import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('changeRolSelector', $event.value))),
    options: _ctx.roles,
    modelValue: _ctx.selRol,
    optionLabel: "name",
    optionValue: "id",
    placeholder: "Seleccionar Rol",
    class: "p-mb-2 w100"
  }, null, 8, ["options", "modelValue"]))
}