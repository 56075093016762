
import { defineComponent, ref, computed, watch } from 'vue'
export default defineComponent({
  props: {
    selectedRol: {
      type: String,
      required: true,
      default: null
    },
    selectedCourse: {
      type: String,
      required: true,
      default: null
    },
    uniqueId: {
      type: String,
      required: true,
      default: null
    }
  },
  emits: ['changeRolSelector'],
  setup (props) {
    const roles = [{ id: 'teacher', name: 'Profesor' }, { id: 'tutor', name: 'Tutor' }, { id: 'coordinator', name: 'Coordinador' }]
    const selRol = ref<string>()
    const id = computed(() => props.uniqueId)
    const course = computed(() => props.selectedCourse)
    const rol = computed(() => props.selectedRol)
    watch(rol, () => {
      if (id.value === 'id_' + course.value) {
        selRol.value = rol.value
      }
    })
    watch(course, () => {
      if (id.value === 'id_' + course.value) {
        selRol.value = rol.value
      }
    })
    return {
      id,
      course,
      rol,
      roles,
      selRol
    }
  }
})
