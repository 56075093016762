
import { defineComponent, PropType, ref } from 'vue'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { toggleElementsFromArray } from '@/utils/helpers'
import RolSelector from '@/components/commons/selectors/RolSelector.vue'
import { PersonRoles } from '@/models/person/enums'
export default defineComponent({
  components: {
    RolSelector
  },
  props: {
    label: {
      type: String,
      required: true,
      default: null
    },
    placeholder: {
      type: String,
      required: true,
      default: null
    },
    helperText: {
      type: String,
      default: null
    },
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    coursePlans: {
      type: Array as PropType<CoursePlan[]>,
      default: () => []
    },
    selectedRol: {
      type: String,
      required: true,
      default: null
    },
    creationMod: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue', 'changeRolCanBeTeacherOf'],
  setup (props) {
    const selRol = ref<string>(props.selectedRol)
    const selCourse = ref<string>()
    const changeRolSelector = (rol: PersonRoles, course: string) => {
      selCourse.value = course
      selRol.value = rol
    }
    return {
      selRol,
      selCourse,
      toggleElementsFromArray,
      changeRolSelector
    }
  }
})
